import * as styles from "./delivery-options.module.scss";
import classNames from "classnames";
import { useEffect, useLayoutEffect, useState } from "react";
import { ButtonResult, ButtonType, InputSelect, InputText, Loader, popup, PopupSize, setPopupButton } from "ui";
import { Country, DeliveryMethod, DeliveryMethodSubTypes, DeliveryOption, DeliveryOptionType, DeliveryMethod as IDeliveryMethod } from 'ui/src/types';

type CheckoutData = {
    deliveryMethodSubTypes: number[];
    deliveryMethods: DeliveryMethod[];
    addressCountries: Country[];
} & {
    [k: string]: any;
};

type CustomerAddress = {
    Id: string;
    Name: string;
    StreetAndStreetNumber: string;
    ZipCode: string;
    City: string;
    CountryRegionId: string;
    Country: {
        Name: string;
        TwoLetterCode: string;
        ThreeLetterCode: string;
        NumericCode: string;
    } | null;
    IsOneTimeAddress: boolean;
}

const translations = window.app.preloadState.translation;
const checkoutState: CheckoutData = window.app.preloadState.checkout;

type DeliveryMethodsProps = {
    deliveryMethods: IDeliveryMethod[];
    showFreightPopup: () => void;
    onDeliveryChange: () => void;
    onValueChange: (isDeliveryMethodValid: boolean) => void;
};

export const DeliveryMethods = ({ deliveryMethods, showFreightPopup, onDeliveryChange, onValueChange }: DeliveryMethodsProps) => {
    const [selectedIndex, setSelectedIndex] = useState(deliveryMethods.findIndex(m => m.isSelected));
    const [isTransitMethodValid, setIsTransitMethodValid] = useState(checkoutState.selectedMethodSubType != -1);
    const [transitId, setTransitId] = useState(deliveryMethods[0].options.find(o => o.isSelected)?.id ?? -1);
    const [pickUpId, setpickUpId] = useState(deliveryMethods[1].options.find(o => o.isSelected)?.id ?? -1);
    const [methodId, setMethodId] = useState(deliveryMethods.filter(dm => dm.isSelected)[0]?.methodType ?? 0);

    useEffect(() => {
        onValueChange((methodId == 1 && pickUpId != -1) || (methodId == 0 && isTransitMethodValid && transitId != -1));
    }, [isTransitMethodValid, pickUpId, transitId, methodId ]);

    useEffect(() => {
        setSelectedIndex(deliveryMethods.findIndex(m => m.isSelected));
    }, [deliveryMethods]);

    const onOption = async (method: number, id: string | null) => {
        setMethodId(method);
        method == 0 ? setTransitId(id != null ? +id : -1) : setpickUpId(id != null ? +id : -1)
        if (id == null || id == '-1') return;
        const body = method === 0
            ? { deliveryMethodType: method, deliveryAddressId: id }
            : { deliveryMethodType: method, deliveryBranchId: id };

        const response = await fetch(`/api/cart/update`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(body),
            method: "POST"
        });
        if (!response.ok) {
            console.error(await response.text());
        }

        if (method === 1) {
            const response = await fetch('/api/inventory/branch/' + id, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                method: 'PUT'
            });
            if (!response.ok) {
                console.error(await response.text());
            }
        }
        onDeliveryChange();
    };

    return (
        <>
            <h3 className={styles.title}>{translations["checkout.deliveryOptions.Title"]}</h3>
            <div>
                <DeliveryMethodTransit
                    method={deliveryMethods[0]}
                    deliveryMethodSubtypes={checkoutState.deliveryMethodSubTypes}
                    selectedMethodSubtype={checkoutState.selectedMethodSubType}
                    isSelected={selectedIndex === 0}
                    isAvailable={deliveryMethods[0].isAvailable}
                    onSelect={() => { setSelectedIndex(0); onOption(deliveryMethods[0].methodType, transitId.toString()); }}
                    showFreightPopup={showFreightPopup}
                    onSubTypeChange={setIsTransitMethodValid}
                    onOption={(id) => onOption(deliveryMethods[0].methodType, id)}
                    countries={checkoutState.addressCountries}
                    manageDeliveryAddressesLink={checkoutState.manageDeliveryAddressesLink ?? "" }
                />
                <DeliveryMethodPickUp
                    method={deliveryMethods[1]}
                    isSelected={selectedIndex === 1}
                    isAvailable={deliveryMethods[1].isAvailable}
                    onSelect={() => { setSelectedIndex(1); onOption(deliveryMethods[1].methodType, pickUpId.toString()); }}
                    showFreightPopup={showFreightPopup}
                    onOption={(id) => onOption(deliveryMethods[1].methodType, id)} />
            </div>
        </>
    );
};

type DeliveryMethodTransitProps = {
    deliveryMethodSubtypes: DeliveryMethodSubTypes[];
    selectedMethodSubtype: DeliveryMethodSubTypes | null;
    method: IDeliveryMethod;
    isSelected: boolean;
    isAvailable: boolean;
    onSelect: () => void;
    showFreightPopup: () => void;
    onOption: (id: string) => void;
    onSubTypeChange: (isValid: boolean) => void;
    countries: Country[];
    manageDeliveryAddressesLink: string;
};

const DeliveryMethodTransit = ({ deliveryMethodSubtypes, selectedMethodSubtype, method, isSelected, isAvailable, onSelect, showFreightPopup, onOption, onSubTypeChange, countries, manageDeliveryAddressesLink }: DeliveryMethodTransitProps) => {

    const [deliveryAddressId, setDeliveryAddressId] = useState(method.options.find(o => o.isSelected)?.id ?? "-1");
    const sideText = translations["checkout.deliveryOptions.Costs." + method.methodName];

    const [isLoader, setIsLoader] = useState(false);

    const [selectedSubType, setSelectedSubType] = useState(selectedMethodSubtype);

    const updateSubType = async (value: DeliveryMethodSubTypes | null) => {
        onSubTypeChange(value != null && value >= 0);

        if (value != null && value >= 0) {
            const userApiResponse = await fetch('/api/user/selected-method-subtype', {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                method: 'PUT',
                body: JSON.stringify(value)
            });
            if (!userApiResponse.ok) {
                console.error(await userApiResponse.text());
            }

            const body = { deliveryMethodSubType: value };
            const cartApiResponse = await fetch(`/api/cart/update`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify(body),
                method: "POST"
            });
            if (!cartApiResponse.ok) {
                console.error(await cartApiResponse.text());
            }
        }
    }



    const newAddressPopup = async () => {
        let address: Address | null = null;
        const result = await popup(translations["checkout.deliveryOptions.NewAddressPopUp.ChangeDeliveryAddress"],
            <NewAddressPopup updateAddress={a => address = a} countries={countries} manageDeliveryAddressesLink={manageDeliveryAddressesLink } />,
            [
                { label: translations["checkout.deliveryOptions.NewAddressPopUp.Cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translations["checkout.deliveryOptions.NewAddressPopUp.Save"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.Small
        );

        if (result == ButtonResult.Cancel || !address) {
            return;
        }
        setIsLoader(true);
        const newAddressResponse = await fetch(`/api/user/addresses`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(address),
            method: "POST"
        });
        if (!newAddressResponse.ok) {
            setIsLoader(false);
            console.error(await newAddressResponse.text());
        }

        const addressesResponse = await fetch(`/api/cart/addresses`, {
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            method: "GET"
        });
        if (!addressesResponse.ok) {
            setIsLoader(false);
            console.error(await addressesResponse.text());
        }
        const addresses: DeliveryOption[] = await addressesResponse.json();
        const newAddressId = addresses.find(a => !method.options.some(o => o.id === a.id))?.id;
        method.options = addresses;
        setDeliveryAddressId(newAddressId ?? "-1");
        setIsLoader(false);
    };

    const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);

    useEffect(() => {
        if (isFirstTimeRender && isSelected) {
            onOption(deliveryAddressId);
        }
    }, [isFirstTimeRender]);

    return <div className={classNames("form-radio", styles.radioWrapper, !isAvailable && styles.inactive)}>
        <input id={method.methodName} type="radio" checked={isSelected} onChange={onSelect} />
        <label htmlFor={method.methodName}>
            <p className={styles.radioLabel}>{translations["checkout.deliveryOptions." + method.methodName]}<span onClick={() => method.methodName === "Delivery" && showFreightPopup()}>{sideText}</span></p>
            {deliveryMethodSubtypes.length > 0 && isSelected && <div className={styles.dropdownWrapper}>
                <span className="dropdown__label">{translations["checkout.deliveryMethodsDropdownLabel"]}</span>
                <select className={styles.deliveryOptions} disabled={!isSelected} value={selectedSubType ?? -1} onChange={(ev) => { setSelectedSubType(+ev.target.value); updateSubType(+ev.target.value); setIsFirstTimeRender(false) }}>
                    <option value="-1">{translations["checkout.selectDeliverySubTypeOption"]}</option>
                    {deliveryMethodSubtypes.map(deliveryMethodSubtype => <option value={deliveryMethodSubtype} key={deliveryMethodSubtype}>
                        {translations[`checkout.deliveryMethodSubtypes.${DeliveryMethodSubTypes[deliveryMethodSubtype]}`]}
                    </option>)}
                </select>
                {((selectedSubType ?? -1) == -1 && !isFirstTimeRender) && <span className="form-error">{translations["checkout.deliveryMethodSubtypeNotSelected"]}</span>}
            </div>}
            {method.options.length > 0 && isSelected && <div className={styles.dropdownWrapper}>
                <span className="dropdown__label">{translations["checkout.deliveryOptions.SelectDeliveryAddress"]}</span>
                <select className={styles.deliveryOptions} disabled={!isSelected} value={deliveryAddressId} onChange={ev => { setDeliveryAddressId(ev.target.value); onOption(ev.target.value); setIsFirstTimeRender(false) }}>
                    <option value="-1">{translations["checkout.selectDeliveryAddressOption"]}</option>
                    {method.options.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(deliveryOption => <option value={deliveryOption.id} key={deliveryOption.id}>
                        {deliveryOption.displayName}
                    </option>)}
                </select>
                {isLoader && <Loader inline />}
                {(deliveryAddressId == '-1' && !isFirstTimeRender) && <span className="form-error">{translations["checkout.deliveryAddressRequired"]}</span>}
            </div>}
            {isSelected && <a onClick={newAddressPopup} className={classNames(styles.radioLabel, styles.tip)}>
                {translations["checkout.deliveryOptions.Manage"]}
            </a>}
        </label>
    </div>
}

const DeliveryMethodPickUp = ({ method, isSelected, isAvailable, onSelect, showFreightPopup, onOption }: { method: IDeliveryMethod, isSelected: boolean, isAvailable: boolean, onSelect: () => void, showFreightPopup: () => void, onOption: (id: string) => void }) => {

    const [deliveryAddressId, setDeliveryAddressId] = useState(method.options.find(o => o.isSelected)?.id ?? "-1");
    const sideText = translations["checkout.deliveryOptions.Costs." + method.methodName];

    const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);

    useEffect(() => {
        if (isFirstTimeRender && isSelected) {
            onOption(deliveryAddressId);
        }
    }, [isFirstTimeRender]);

    return <div className={classNames("form-radio", styles.radioWrapper, !isAvailable && styles.inactive)}>
        <input id={method.methodName} type="radio" checked={isSelected} onChange={onSelect} />
        <label htmlFor={method.methodName}>
            <p className={styles.radioLabel}>{translations["checkout.deliveryOptions." + method.methodName]}<span onClick={() => method.methodName==="Delivery" && showFreightPopup()}>{sideText}</span></p>
            {isSelected && <div className={styles.dropdownWrapper}>
                <select className={styles.deliveryOptions} disabled={!isSelected || method.options.length == 0} value={deliveryAddressId} onChange={ev => { setDeliveryAddressId(ev.target.value); onOption(ev.target.value); setIsFirstTimeRender(false); }}>
                    <option value="-1">{translations["checkout.selectBranchOption"]}</option>
                    {method.options.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(deliveryOption => <option value={deliveryOption.id} key={deliveryOption.id}>
                        {deliveryOption.displayName}
                    </option>)}
                </select>
                {(deliveryAddressId == '-1' && !isFirstTimeRender) && <span className="form-error">{translations["checkout.deliveryBranchNotSelected"]}</span>}
            </div>}
        </label>
    </div>
}

export const DeliveryOptions = ({ initialDeliveryOption, fullDeliveryText, partialDeliveryText, onDeliveryChange, hasMoreThanOneProduct }: { initialDeliveryOption: DeliveryOptionType | null, fullDeliveryText: string, partialDeliveryText: string, onDeliveryChange: () => void, hasMoreThanOneProduct: boolean }) => {

    const [deliveryOption, setDeliveryOption] = useState(initialDeliveryOption ?? DeliveryOptionType.Partial);

    const updateOption = (value: DeliveryOptionType) => async () => {
        setDeliveryOption(value);
        const response = await fetch(`/api/cart/update`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify({
                deliveryOptionType: value
            }),
            method: "POST"
        });
        if (!response.ok) {
            console.error(await response.text());
        }
        onDeliveryChange();
    }

    useEffect(() => {
        if (!hasMoreThanOneProduct)
            updateOption(DeliveryOptionType.Full)();

    }, [hasMoreThanOneProduct])

    if (!hasMoreThanOneProduct)
        return <></>;

    return (
        <div className={styles.deliveryOptionsContainer}>
            <h3 className={styles.title}>{translations["checkout.deliveryOptions.DeliveryOptionsHeading"]}</h3>
            <div className={styles.deliveryContainer}>
                <input id={"fullDelivery"} type="radio" checked={deliveryOption === DeliveryOptionType.Full} onChange={updateOption(DeliveryOptionType.Full)} />
                <label htmlFor={"fullDelivery"}>{translations["checkout.deliveryOptions.FullDelivery"]}</label>
                <div className={styles.deliveryText} style={{ height: deliveryOption === DeliveryOptionType.Full ? 'unset' : '0' }}
                    dangerouslySetInnerHTML={{ __html: fullDeliveryText }}>
                </div>
            </div>
            <div className={styles.deliveryContainer}>
                <input id={"partialDelivery"} type="radio" checked={deliveryOption === DeliveryOptionType.Partial} onChange={updateOption(DeliveryOptionType.Partial)} />
                <label htmlFor={"partialDelivery"}>{translations["checkout.deliveryOptions.PartialDelivery"]}</label>
                <div className={styles.deliveryText} style={{ height: deliveryOption === DeliveryOptionType.Partial ? 'unset' : '0' }}
                    dangerouslySetInnerHTML={{ __html: partialDeliveryText }}>
                </div>
            </div>
        </div>
    );
}

type Address = {
    name: string;
    streetAndStreetNumber: string;
    zipCode: string;
    city: string;
    countryRegionId: string;
};

type NewAddressPopupProps = {
    updateAddress: (a: Address) => void;
    countries: Country[];
    manageDeliveryAddressesLink: string;
};

const NewAddressPopup = ({ updateAddress, countries, manageDeliveryAddressesLink }: NewAddressPopupProps) => {
    const [address, setAddress] = useState<Address>({
        name: "",
        streetAndStreetNumber: "",
        zipCode: "",
        city: "",
        countryRegionId: "",
    });

    useEffect(() => {
        updateAddress(address);
        const isAddressValid = Object.values(address).every(v => v.trim().length > 0);
        setPopupButton(1, isAddressValid);
    }, [address]);

    const updater = (prop: keyof Address) => ({
        name: prop,
        value: address[prop],
        onChange: (ev: React.ChangeEvent<HTMLInputElement> | string) => {
            setAddress({
                ...address,
                [prop]: typeof ev === 'string' ? ev : ev.target.value
            });
        }
    });

    return (<div>
        <p>{translations["checkout.deliveryOptions.NewAddressPopUp.Description.BeforeLink"]}
            <a href={manageDeliveryAddressesLink}>{translations["checkout.deliveryOptions.NewAddressPopUp.Description.Link"]}</a>
            {translations["checkout.deliveryOptions.NewAddressPopUp.Description.AfterLink"]}
        </p>
        <div className={styles.newAddressPopUpInputs}>
            <InputText label={translations["checkout.deliveryOptions.NewAddressPopUp.CompanyNameOrNameOfRecipient"]} {...updater('name')} />
            <InputText label={translations["checkout.deliveryOptions.NewAddressPopUp.PostalAddress"]} {...updater('streetAndStreetNumber')} />
            <InputText label={translations["checkout.deliveryOptions.NewAddressPopUp.ZIPCode"]} {...updater('zipCode')} />
            <InputText label={translations["checkout.deliveryOptions.NewAddressPopUp.Place"]} {...updater('city')} />
            <InputSelect label={translations["checkout.deliveryOptions.NewAddressPopUp.Country"]} placeholder={translations["checkout.deliveryOptions.NewAddressPopUp.CountryPlaceholder"]} {...updater('countryRegionId')} options={countries.map(c => ({ label: c.displayName, value: c.twoLetterCode }))} />
        </div>
    </div>);
}